import { colors } from "@mui/material";
import { atticusColors } from "./colors";
import { PaletteOptions } from "@mui/material/styles/createPalette";

// const defaultTheme = createTheme();

// NOTE: for recognized properties: https://mui.com/customization/default-theme/
//  more details: https://mui.com/customization/theming/
const lightPalette: PaletteOptions = {
  mode: "light",
  background: {
    default: atticusColors.grey7,
    paper: atticusColors.white,
  },
  primary: {
    main: atticusColors.green4,
    light: atticusColors.green3,
    dark: atticusColors.green5,
    contrastText: atticusColors.white,
  },
  secondary: {
    main: atticusColors.purple4,
    light: atticusColors.purple3,
    dark: atticusColors.purple5,
    contrastText: atticusColors.white,
  },
  error: {
    main: atticusColors.fuchsia4,
    light: atticusColors.fuchsia3,
    dark: atticusColors.fuchsia5,
    contrastText: atticusColors.white,
  },
  // warning: {
  //   // light: atticusColors.red2,
  //   // main: atticusColors.red1,
  //   // dark: atticusColors.green1,
  //   // contrastText: atticusColors.white,
  // },
  // info: {
  //   // light: atticusColors.red2,
  //   // main: atticusColors.red1,
  //   // dark: atticusColors.green1,
  //   // contrastText: atticusColors.white,
  // },
  success: {
    light: atticusColors.purple1,
    main: atticusColors.purple2,
  },
  text: {
    primary: atticusColors.text1,
    secondary: atticusColors.text2,
    disabled: atticusColors.text3,
  },
  divider: atticusColors.grey6,
  common: {
    black: colors.common.black,
    white: atticusColors.white,
  },
};

// TODO: implement dark theme
// const darkPalette = {
//   mode: "dark",
//   background: {
//     default: atticusColors.grey3,
//     paper: atticusColors.grey2,
//   },
//   primary: {
//     light: atticusColors.green1,
//     main: atticusColors.green2,
//     dark: atticusColors.purple0,
//     contrastText: colors.common.black,
//   },
//   secondary: {
//     main: atticusColors.purple2,
//     dark: atticusColors.purple3,
//   },
//   error: {
//     main: atticusColors.red2,
//     light: atticusColors.red1,
//   },
//   text: {
//     primary: atticusColors.white,
//     secondary: atticusColors.grey7,
//     disabled: atticusColors.grey6,
//     hint: atticusColors.grey5,
//   },
//   divider: atticusColors.grey3,
//   common: {
//     black: atticusColors.white,
//     white: colors.common.black,
//   },
// };
//
// const dark = createTheme({
//   palette: darkPalette,
//   typography: getTypography(darkPalette, defaultTheme),
//   overrides: getComponents(darkPalette, defaultTheme),
// });
export const palettes = {
  light: lightPalette,
};
