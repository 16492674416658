import { Logger } from "aws-amplify";
import { CONSTANTS } from "../constants";

enum LogLevels {
  VERBOSE = "VERBOSE",
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
}

/**
 * AtticusLogger handles client-side logging
 *  log of type error will be sent to the server via log method by default
 *  info and warn can be optionally sent to the server, but will not by default
 */
export class AtticusLogger {
  static LogLevels = LogLevels;
  private sessionId?: string = "";
  constructor(
    private logger: Logger,
    private log: (data: any) => Promise<void>
  ) {
    this.sessionId =
      window.sessionStorage.getItem(CONSTANTS.STORAGE_KEYS.LOG_SESSION_ID) ||
      "";
  }

  sendToServer(
    msg: string | Record<string, unknown> | Error,
    level = LogLevels.ERROR
  ): void {
    this.log({
      level: level,
      message: typeof msg === "string" ? msg : JSON.stringify(msg),
      timestamp: new Date().toLocaleString(),
      sessionId: this.sessionId,
    });
  }
  verbose(msg: string | Record<string, unknown>): void {
    this.logger.verbose(msg);
  }
  debug(msg: string | Record<string, unknown> | Error): void {
    this.logger.debug(msg);
  }
  info(msg: string | Record<string, unknown>, sendToServer = false): void {
    if (sendToServer) this.sendToServer(msg, LogLevels.INFO);
    this.logger.info(msg);
  }
  warn(msg: string | Record<string, unknown>, sendToServer = false): void {
    if (sendToServer) this.sendToServer(msg, LogLevels.WARN);
    this.logger.warn(msg);
  }
  error(
    msg: string | Record<string, unknown> | Error,
    sendToServer = true
  ): void {
    if (sendToServer) this.sendToServer(msg, LogLevels.ERROR);
    this.logger.error(msg);
  }
}
