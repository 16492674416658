export const CONSTANTS = {
  STORAGE_KEYS: {
    THEME_KEY: "AtticusThemeKey",
    TOKEN: "AtticusAdvisorToken",
    LOG_SESSION_ID: "AtticusAdvisorSessionId",
    BYPASS: "AtticusMaintenance",
    OVERRIDE: "AtticusConfig",
  },
  COOKIES: {
    authToken: "AtticusAuth",
    inviteId: "AtticusInviteId",
  },
  SESSION_IDLE_MINUTES: 20,
  AUTO_LOGOUT_SECONDS: 30,
  ADVISOR_GROUP_PREFIX: "AG-",
  REGEX: {
    phone: new RegExp(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    ),
    email: new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/g
    ),
    password: new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/g
    ),
  },
};
