export enum Roles {
  "Primary_End-User" = 1,
  "Advisor" = 2, // really? there's two ways to spell this?
  "Advisor_Admin" = 3,
  "Super_Advisor_Admin" = 4,
  "Uber_Admin" = 5,
}
export enum SpecialCognitoGroups {
  Uber = "Uber",
  Admin = "Admin",
  OnlyAdminTool = "OnlyAdminTool",
  OnlyMetricsTool = "OnlyMetricsTool",
  MetricsTool = "MetricsTool",
}
