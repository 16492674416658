export const enumToArrayString = (theEnum: any): string[] => {
  return Object.values(theEnum)
    .map((value: any) => (typeof value === "string" ? value : null))
    .filter((n): n is string => n !== null);
};

export const enumToStringDictionary = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  theEnum: any
): Record<string, string> => {
  const dic: Record<string, string> = {};
  Object.entries(theEnum).forEach((entry: any) => {
    dic[entry[0] as string] = entry[1] as string;
  });
  return dic;
};
