export enum Environments {
  prod = "production",
  dev = "development",
  test = "test",
}
// web-flow app
export enum MarketingApp {
  prod = "https://www.weareatticus.com",
}
// atticus-web-dev (VUE app)
export enum ConsumerApp {
  prod = "https://app.weareatticus.com",
  ppd1 = "https://app-preprod1.weareatticus.com",
  ppd2 = "https://app-preprod2.weareatticus.com",
  local = "http://localhost:3000",
}
// atticus-advisor (React app)
export enum AdvisorApp {
  prod = "https://advisor.weareatticus.com",
  ppd1 = "https://advisor-ppd1.weareatticus.com",
  local = "http://localhost:3001",
}
// atticus-app (React app)
export enum ConsumerApp2 {
  prod = "https://app2.weareatticus.com",
  ppd1 = "https://app-ppd1.weareatticus.com",
  ppd2 = "https://app-ppd2.weareatticus.com",
  local = "http://localhost:3002",
}
// atticus-forms (React NextJS SSG app)
export enum FormsApp {
  prod = "https://forms.weareatticus.com",
  dev = "https://forms-dev.weareatticus.com",
  local = "http://localhost:3006",
}
export const isProd = process.env.NODE_ENV === Environments.prod;
export const isDev = process.env.NODE_ENV === Environments.dev;
export const isTest = process.env.NODE_ENV === Environments.test;
